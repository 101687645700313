// extracted by mini-css-extract-plugin
export var innerDiv = "{mysqlAllArticles-pageUrl}-module--innerDiv--3JfQQ";
export var title = "{mysqlAllArticles-pageUrl}-module--title--3kvQ3";
export var topTitle = "{mysqlAllArticles-pageUrl}-module--topTitle--1lAzp";
export var columnsContainer = "{mysqlAllArticles-pageUrl}-module--columnsContainer--gcKQt";
export var leftColumn = "{mysqlAllArticles-pageUrl}-module--leftColumn--hx7tP";
export var rightColumn = "{mysqlAllArticles-pageUrl}-module--rightColumn--29g1j";
export var introTextTop = "{mysqlAllArticles-pageUrl}-module--introTextTop--3cNUF";
export var goToAllArticlesMainDiv = "{mysqlAllArticles-pageUrl}-module--goToAllArticlesMainDiv--2Git0";
export var goToAllArticlesButton = "{mysqlAllArticles-pageUrl}-module--goToAllArticlesButton--3H3Rp";
export var publishAtP = "{mysqlAllArticles-pageUrl}-module--publishAtP--3FFze";
export var topTitleBreadcrumbs = "{mysqlAllArticles-pageUrl}-module--topTitleBreadcrumbs--22ooN";
export var breadcrumbLink = "{mysqlAllArticles-pageUrl}-module--breadcrumbLink--3pFyu";
export var breadcrumbArrow = "{mysqlAllArticles-pageUrl}-module--breadcrumbArrow--riYZh";
export var ingredientsStepsContainer = "{mysqlAllArticles-pageUrl}-module--ingredientsStepsContainer--k29Fy";
export var relatedRecipesTitleSpan = "{mysqlAllArticles-pageUrl}-module--relatedRecipesTitleSpan--3gRWJ";