import { innerDiv, title, leftColumn, rightColumn, columnsContainer, publishAtP, goToAllArticlesMainDiv,
  goToAllArticlesButton, topTitleBreadcrumbs, breadcrumbLink, breadcrumbArrow } from './{mysqlAllArticles.pageUrl}.module.css'
import React from "react"
import NavBar from "../../components/NavBar"
import { graphql } from 'gatsby'
import Footer from "../../components/Footer"
import RecipeRightBar from "../../components/RecipeRightBar/RecipeRightBar"
import { Helmet } from "react-helmet"
import { navigate } from 'gatsby';
import Comments from "../../components/Comments"
import CarouselHome from "../../components/CarouselHome"
import { Link } from "gatsby"

const ArticlePage = ({ data }) => {
  const articleData = data.allMysqlAllArticles.nodes[0];

  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  var publishedAt = "";
  var isoDate = "";
  if (articleData.publishedAt) {
    publishedAt = new Date(Date.parse(articleData.publishedAt)).toLocaleDateString("es-ES", dateOptions);
    isoDate = new Date(Date.parse(articleData.publishedAt)).toISOString();
  }

  const goToAllArticles = () => {
    navigate(`/todos-los-articulos`);
  }

  var contentReplaced = articleData.content.replace(/<h2/g, "<h2 class='fullHtmlH2'")
  .replace(/<p/g, "<p class='fullHtmlP'")
  .replace(/<ul/g, "<ul class='fullHtmlIngredientsUl'")
  .replace(/<li/g, "<li class='fullHtmlIngredientsLi'")
  .replace(/YouTube/g, '<a href="https://www.youtube.com/FedeCocina" target="_blank" rel="noreferrer" aria-label="YouTube">YouTube</a>')
  .replace(/Instagram/g, '<a href="https://www.instagram.com/fede.cocina.ya" target="_blank" rel="noreferrer" aria-label="Instagram">Instagram</a>')
  .replace(/Facebook/g, '<a href="https://www.facebook.com/fede.cocina.ya" target="_blank" rel="noreferrer" aria-label="Facebook">Facebook</a>');

  var imgIndex = contentReplaced.indexOf("{IMG<");
  
  function getCleanedStr(str) {
    if (!str) {
      return "";
    }
    
    var retval = str.replace(/\{ACTION_BUTTON\}/g, "");
    retval = retval.replace(/<p>/g, "");
    retval = retval.replace(/<\/p>/g, "");
    retval = retval.replace(/<b>/g, "");
    retval = retval.replace(/<\/b>/g, "");
    retval = retval.replace(/<i>/g, "");
    retval = retval.replace(/<\/i>/g, "");
    retval = retval.replace(/\n/g, "");
    retval = retval.replace(/"/g, '\\"');

    return retval;
  }

  while (imgIndex >= 0) {
    var data1SeparatorIndex = contentReplaced.indexOf(":", imgIndex);
    var data2SeparatorIndex = contentReplaced.indexOf(":", data1SeparatorIndex + 1);
    var closeIndex = contentReplaced.indexOf(">}", data2SeparatorIndex);
    var imgUrl = contentReplaced.substring(imgIndex + 5, data1SeparatorIndex);
    var fullStr = contentReplaced.substring(imgIndex, closeIndex + 2);

    var width = contentReplaced.substring(data1SeparatorIndex + 1, data2SeparatorIndex);
    var height = contentReplaced.substring(data2SeparatorIndex + 1, closeIndex);

    var altText = imgUrl.replace('/articulos/', '');
    altText = altText.replace(/-/g, ' ');
    altText = altText.replace('.png', '');
    altText = altText.replace('.jpg', '');

    var imgToReplace = "<img class='articleImg' width='" + width + "' height='" + height + "' src='/imagenes" + imgUrl + "' alt='" + altText + "'></img>";

    contentReplaced = contentReplaced.replace(fullStr, imgToReplace);
    imgIndex = contentReplaced.indexOf("{IMG<");
  }

  const nameCleaned = getCleanedStr(articleData.name);
  const shortNameCleaned = getCleanedStr(articleData.shortName);
  const introTextCleaned = getCleanedStr(articleData.introText);

  return (
    <div>
      <Helmet>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
          <meta charSet="utf-8" />
          <title>{nameCleaned} - Fede Cocina</title>
          <meta name="description" content={introTextCleaned}></meta>
          <html lang="es"/>
          <meta property="og:title" content={nameCleaned}></meta>
          <meta property="og:description" content={introTextCleaned}></meta>
          <meta property="og:image" content={"https://fedecocina.net/imagenes/16x9/" + articleData.image + ".jpg"}></meta>
          <meta property="og:image:width" content="1280"></meta>
          <meta property="og:image:height" content="720"></meta>
          <meta property="og:type" content="article"></meta>
          <meta property="og:locale" content="es_ES"></meta>
          <meta property="og:site_name" content="fedecocina.net"></meta>
          <script type="application/ld+json">
          {`{
            "@context": "https://schema.org/",
            "@graph": [
              {
                "@type": "Organization",
                "@id": "https://fedecocina.net/#organization",
                "name": "Fede Cocina",
                "url": "https://fedecocina.net/",
                "sameAs": [
                  "https://www.facebook.com/fede.cocina.ya",
                  "https://www.instagram.com/fede.cocina.ya",
                  "https://www.youtube.com/FedeCocina"
                ],
                "logo": {
                  "@type": "ImageObject",
                  "@id": "https://fedecocina.net/#logo",
                  "inLanguage": "es-AR",
                  "url": "https://fedecocina.net/imagenes/logo.png",
                  "width": 800,
                  "height": 800,
                  "caption": "Fede Cocina"
                },
                "image": {
                  "@id": "https://fedecocina.net/#logo"
                }
              },
              {
                "@type": "WebSite",
                "@id": "https://fedecocina.net/#website",
                "url": "https://fedecocina.net/",
                "name": "Fede Cocina",
                "description": "Las recetas más fáciles y rapidas para que prepares en tu casa.",
                "publisher": {
                  "@id": "https://fedecocina.net/#organization"
                },
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://fedecocina.net/busqueda-receta?search={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "es-AR"
              },
              {
                "@type": "WebPage",
                "@id": "https://fedecocina.net/articulo/` + articleData.pageUrl + `/#webpage",
                "url": "https://fedecocina.net/articulo/` + articleData.pageUrl + `/",
                "headline": "` + shortNameCleaned + ` - Fede Cocina",
                "name": "` + shortNameCleaned + ` - Fede Cocina",
                "isPartOf": {
                  "@id": "https://fedecocina.net/#website"
                },
                "primaryImageOfPage": {
                  "@id": "https://fedecocina.net/articulo/` + articleData.pageUrl + `/#primaryimage"
                },
                "datePublished": "2019-04-25T07:15:59+00:00",
                "description": "` + introTextCleaned + `",
                "breadcrumb": {
                  "@id": "https://fedecocina.net/articulo/` + articleData.pageUrl + `/#breadcrumb"
                },
                "inLanguage": "es-AR",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://fedecocina.net/articulo/` + articleData.pageUrl + `/"
                    ]
                  }
                ]
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://fedecocina.net/articulo/` + articleData.pageUrl + `/#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@type": "WebPage",
                      "@id": "https://fedecocina.net/",
                      "url": "https://fedecocina.net/",
                      "name": "Home"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@type": "WebPage",
                      "@id": "https://fedecocina.net/todos-los-articulos/",
                      "url": "https://fedecocina.net/todos-los-articulos/",
                      "name": "Todos los Articulos"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                      "@type": "WebPage",
                      "@id": "https://fedecocina.net/articulo/` + articleData.pageUrl + `/",
                      "url": "https://fedecocina.net/articulo/` + articleData.pageUrl + `/",
                      "name": "` + nameCleaned + `"
                    }
                  }
                ]
              },
              {
                "@type": "Person",
                "@id": "https://fedecocina.net/#person",
                "name": "Federico Quevedo",
                "image": {
                  "@type": "ImageObject",
                  "@id": "https://fedecocina.net/#personlogo",
                  "inLanguage": "es-AR",
                  "url": "https://fedecocina.net/imagenes/Federico Quevedo.jpg",
                  "caption": "Federico Quevedo",
                  "width": 800,
                  "height": 800
                }
              },
              {
                "@context": "http://schema.org/",
                "@type": "Article",
                "articleSection": "Cooking",
                "name": "` + nameCleaned + `",
                "author": {
                  "@type": "Person",
                  "name": "Federico Quevedo",
                  "url": "https://www.instagram.com/federico.quevedo/"
                },
                "datePublished": "` + isoDate + `",
                "description": "` + introTextCleaned + `",
                "headline": "` + shortNameCleaned + `",
                "@id": "https://fedecocina.net/articulo/` + articleData.pageUrl + `/#article",
                "mainEntityOfPage": "https://fedecocina.net/articulo/` + articleData.pageUrl + `/#webpage",
                "image": [
                  "https://fedecocina.net/imagenes/1x1/` + articleData.image + `.jpg",
                  "https://fedecocina.net/imagenes/4x3/` + articleData.image + `.jpg",
                  "https://fedecocina.net/imagenes/16x9/` + articleData.image + `.jpg"
                ]
              }
            ]
          }`}
          </script>
        </Helmet>
      <NavBar></NavBar>
      <CarouselHome whiteBackground={true}></CarouselHome>
      <div className={innerDiv}>
        <p className={topTitleBreadcrumbs}>
          <Link className={breadcrumbLink} to={`/`}>Inicio</Link>
          <span className={breadcrumbArrow}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"></path></svg> </span>
          <Link className={breadcrumbLink} to={`/todos-los-articulos/`}>Trucos</Link>
        </p>
        <div className={columnsContainer}>
          <div className={leftColumn}>
            <h1 className={title} dangerouslySetInnerHTML={{__html: articleData.name}}></h1>
            <div dangerouslySetInnerHTML={{__html: contentReplaced}}></div>
            
            <p className={publishAtP}>Publicado por Federico Quevedo, el {publishedAt}.</p>
            <div className={goToAllArticlesMainDiv} onClick={() => goToAllArticles()}>¿Te quedaste con ganas de leer más? ¡Hay muchos más trucos y artículos para que veas!</div>
            <button className={goToAllArticlesButton} onClick={() => goToAllArticles()}>Ver más Trucos / Artículos</button>
          </div>
          <div className={rightColumn}>
            <RecipeRightBar></RecipeRightBar>
          </div>
        </div>
      </div>
      <div className={innerDiv}>
        <div className={columnsContainer}>
          <div className={leftColumn}>
           <Comments articleData={articleData}></Comments>
          </div>
          <div className={rightColumn}>

          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export const query = graphql`
  query($pageUrl: String) {
    allMysqlAllArticles(filter: {pageUrl: {eq: $pageUrl}}) {
      nodes {
        id
        name
        introText
        content
        publishedAt
        pageUrl
        image
      }
    }
  }
`

export default ArticlePage
